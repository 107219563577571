import { h } from "preact";
import { useState } from "preact/hooks";

export const MOCK_VARIATIONS_KEY = "mock_variations";

const TestFeatureFlags = () => {
  const [featureFlagValues, setFeatureFalgValues] = useState(sessionStorage.getItem(MOCK_VARIATIONS_KEY) ?? "");
  const [invalidValues, setInvalidValues] = useState(false);
  return (
    <div className="tui-test-area">
      <div className="tui-test-patient-context">
        <div className="tui-test-feature-flags">
          <input
            data-testid="tui-feature-flag-values-input"
            className="tui-test-feature-flag-values"
            aria-label="Feature Flag Values"
            placeholder="Feature Flag Values"
            type="text"
            value={featureFlagValues}
            onInput={(evt) => {
              setInvalidValues(false);
              setFeatureFalgValues(evt.target.value);
            }}
          />
        </div>
        <button
          className="tui-test-button"
          data-testid="tui-set-feature-flag-values-button"
          onClick={() => {
            try {
              JSON.parse(featureFlagValues);
            } catch {
              setInvalidValues(true);
              return;
            }
            sessionStorage.setItem(MOCK_VARIATIONS_KEY, featureFlagValues);
            location.reload();
          }}
        >
          Set
        </button>
        <button
          className="tui-test-button"
          data-testid="tui-reset-feature-flag-values-button"
          onClick={() => {
            sessionStorage.removeItem(MOCK_VARIATIONS_KEY);
            location.reload();
          }}
        >
          Reset
        </button>
      </div>
      {invalidValues && (
        <div className="tui-error-message" data-testid="tui-error-message">
          Invalid feature flags values format. Please enter valid JSON.
        </div>
      )}
    </div>
  );
};

export default TestFeatureFlags;
