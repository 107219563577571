import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

const simulateEvent = (simulateMultipleEmisWebInstances) => {
  const event = new CustomEvent("test-simulate-multiple-emis-web-instances", {
    detail: { simulateMultipleEmisWebInstances }
  });
  document.dispatchEvent(event);
};
const TestMultipleEmisWebInstances = () => {
  const [simulateMultipleEmisWebInstances, setSimulateMultipleEmisWebInstances] = useState(
    sessionStorage.getItem("ReducedModeConfirmed") === "true"
  );

  useEffect(() => {}, [simulateMultipleEmisWebInstances]);

  return (
    <div className="tui-test-area">
      <div
        className="tui-simulate-multiple-emis-web-instances"
        data-testid="tui-simulate-multiple-emis-web-instances-testid"
      >
        <label for="tui-simulate-multiple-emis-web-instances-checkbox">Simulate multiple EMIS Web instances</label>
        <input
          checked={simulateMultipleEmisWebInstances}
          data-testid="tui-simulate-multiple-emis-web-instances-checkbox-testid"
          id="tui-simulate-multiple-emis-web-instances-checkbox"
          onClick={() => {
            simulateEvent(!simulateMultipleEmisWebInstances);
            setSimulateMultipleEmisWebInstances(
              (simulateMultipleEmisWebInstances) => !simulateMultipleEmisWebInstances
            );
          }}
          type="checkbox"
        />
      </div>
    </div>
  );
};

export default TestMultipleEmisWebInstances;
