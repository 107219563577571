import { h } from "preact";
import { useState } from "preact/hooks";

const MOCK_CLAIMS_KEY = "mock_claims";

const TestUserContext = () => {
  const [userClaims, setUserClaims] = useState(sessionStorage.getItem(MOCK_CLAIMS_KEY) ?? "");
  const [invalidClaims, setInvalidClaims] = useState(false);
  return (
    <div className="tui-test-area">
      <div className="tui-test-patient-context">
        <div className="tui-test-user-context">
          <input
            data-testid="tui-user-claims-input"
            className="tui-test-user-id"
            aria-label="User Claims"
            placeholder="User Claims"
            type="text"
            value={userClaims}
            onInput={(evt) => {
              setInvalidClaims(false);
              setUserClaims(evt.target.value);
            }}
          />
        </div>
        <button
          className="tui-test-button"
          data-testid="tui-set-user-claims-button"
          onClick={() => {
            try {
              JSON.parse(userClaims);
            } catch {
              setInvalidClaims(true);
              return;
            }
            sessionStorage.setItem(MOCK_CLAIMS_KEY, userClaims);
            location.reload();
          }}
        >
          Set
        </button>
        <button
          className="tui-test-button"
          data-testid="tui-reset-user-claims-button"
          onClick={() => {
            sessionStorage.removeItem(MOCK_CLAIMS_KEY);
            location.reload();
          }}
        >
          Reset
        </button>
      </div>
      {invalidClaims && (
        <div className="tui-error-message" data-testid="tui-error-message">
          Invalid claims format. Please enter valid JSON.
        </div>
      )}
    </div>
  );
};

export default TestUserContext;
