import { h } from "preact";
import { useState } from "preact/hooks";

const TestToastNofications = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [variant, setVariant] = useState("info");
  const [actionButtonText, setActionButtonText] = useState("");

  return (
    <div className="tui-test-area">
      <div className="tui-toast-notification-input">
        <input
          data-testid="tui-notification-input-title"
          className="tui-test-notification-json"
          aria-label="Title"
          placeholder="Title"
          type="text"
          value={title}
          onInput={(evt) => setTitle(evt.target.value)}
        />
        <input
          data-testid="tui-notification-input-text"
          className="tui-test-notification-json"
          aria-label="Text"
          placeholder="Text"
          type="text"
          value={text}
          onInput={(evt) => setText(evt.target.value)}
        />
        <input
          data-testid="tui-notification-input-action-button-text"
          className="tui-test-notification-json"
          aria-label="Action Button Text"
          placeholder="Action Button Text"
          type="text"
          value={actionButtonText}
          onInput={(evt) => setActionButtonText(evt.target.value)}
        />
        <select
          data-testid="tui-notification-type-select"
          value={variant}
          onChange={(evt) => setVariant(evt.target.value)}
        >
          <option value="success">Success</option>
          <option value="info">Info</option>
          <option value="error">Error</option>
        </select>
      </div>
      <button
        className="tui-test-button"
        data-testid="tui-notification-receive-button"
        onClick={() => {
          const notification = {
            title,
            text,
            variant,
            onClose: () => console.log("onClose called")
          };

          if (actionButtonText)
            notification.actionButton = {
              text: actionButtonText,
              onClick: () => console.log("actionButtonOnClick called")
            };

          const event = new CustomEvent("test-toast-notification-receive", {
            detail: {
              notification: {
                data: notification
              }
            }
          });
          document.dispatchEvent(event);
        }}
      >
        Receive
      </button>
      <button
        className="tui-test-button"
        data-testid="tui-notification-example-button"
        onClick={() => {
          setTitle("Example Title");
          setText("Example Text");
          setVariant("info");
          setActionButtonText("Dismiss");
        }}
      >
        Example
      </button>
    </div>
  );
};

export default TestToastNofications;
