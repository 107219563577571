import { h } from "preact";
import { useState } from "preact/hooks";
import TestCountEvent from "./test-count-event";
import TestNofications from "./test-notifications";
import TestToastNofications from "./test-toast-notifications";
import TestPatientContext from "./test-patient-context";
import TestMultipleEmisWebInstances from "./test-emis-web-multiple-instances";
import TestTimeout from "./test-emis-web-timeout";
import TestDiscardDialog from "./test-global-dialog";
import TestUserContext from "./test-user-context";
import TestFeatureFlags from "./test-feature-flags";

const TestType = {
  patientContext: {
    name: "Patient",
    component: <TestPatientContext />
  },
  countEvent: {
    name: "Events",
    component: <TestCountEvent />
  },
  notifications: {
    name: "Notifications",
    component: <TestNofications />
  },
  toastNotifications: {
    name: "Toast Notifications",
    component: <TestToastNofications />
  },
  emisWebMultipleInstances: {
    name: "Multiple EMIS Web",
    component: <TestMultipleEmisWebInstances />
  },
  emisWebTimeout: {
    name: "Timeout",
    component: <TestTimeout />
  },
  discard: {
    name: "Discard",
    component: <TestDiscardDialog />
  },
  user: {
    name: "User",
    component: <TestUserContext />
  },
  featureFlags: {
    name: "FeatureFlags",
    component: <TestFeatureFlags />
  }
};

const Test = () => {
  const [activeTest, setActiveTest] = useState(TestType.patientContext.name);

  const getTabClassName = (testType) => `tui-tablinks${activeTest === testType ? " selected" : ""}`;

  const getDataTestId = (testType) => `tui-test-${testType.toLowerCase().replace(" ", "-")}-tab`;

  return (
    <div>
      <div className="tui-tab">
        {" "}
        {Object.values(TestType).map(({ name }) => (
          <button
            key={name}
            className={getTabClassName(name)}
            data-testid={getDataTestId(name)}
            onClick={() => setActiveTest(name)}
            title={name}
          >
            {name}
          </button>
        ))}
      </div>
      {Object.values(TestType)
        .filter(({ name }) => activeTest === name)
        .map(({ component }) => component)}
    </div>
  );
};

export default Test;
